<template>
  <div class="checkbox-group-view">
    <esmp-checkbox
      v-for="(item, index) in values"
      disabled
      :key="index"
      :value="item.checked"
    >
      {{ item.name }}
    </esmp-checkbox>
  </div>
</template>

<script>
export default {
  name: 'CheckboxGroupView',
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    values() {
      return this.settings.find((el) => el.techName === 'values').values;
    },
  },
};
</script>

<style lang="scss">
.checkbox-group-view {
  display: flex;
  flex-direction: column;
}
</style>
